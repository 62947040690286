import React, { useRef, useState } from "react";
import "./App.css";
import Avatar from "react-avatar-edit";
import amerifyLogo from "./images/amerify.png";
import awardsImages from "./images/awards.png";
import fbIcon from "./images/facebook_icon.png";
import InstaIcon from "./images/instagram_icon.png";
import linkedinIcon from "./images/linkedin_icon.png";
import profileAvatar from "./images/avatar.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    nmls: "1416824",
    nmls_personal: "",
    dre: "",
    directPhone: "",
    faxPhone: "",
    address: "",
    email: "",
    apply_now: "",
    secure_upload: "",
    reviews: "",
    facebook: "",
    instagram: "",
    linkedIn: "",
    schedule_appt: "",
    avatar: null,
  });
  const [errors, setErrors] = useState({});
  const tableRef = useRef(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = {};
    if (!formData.name.trim()) {
      errorMsg.name = "Please fill in the required fields.";
    }
    if (!formData.avatar) {
      errorMsg.avatar = "Please fill in the required fields.";
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      errorMsg.email = "Please fill in the required fields.";
    } else if (!emailPattern.test(formData.email)) {
      errorMsg.email = "Please fill in the required fields.";
    }
    setErrors(errorMsg);
    if (Object.keys(errorMsg).length > 0) {
      return;
    }

    handleCopy();
  };
  const [preview, setPreview] = useState(null);
  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (preview) => {
    setPreview(preview);
  };
  const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 1024000) {
      alert("File is too big!");
      elem.target.value = "";
    }
  };
  const generateEmailTemplate = () => {
    var outerHTML = "";
    if (tableRef.current) {
      outerHTML = tableRef.current.outerHTML;
    }
    const emailTemplate = outerHTML;
    return emailTemplate;
  };
  const handleGenerate = () => {
    const emailTemplate = generateEmailTemplate();
    const textarea = document.getElementById("emailTemplateTextarea");
    textarea.value = emailTemplate;
  };
  const handleClear = () => {
    const textarea = document.getElementById("emailTemplateTextarea");
    textarea.value = "";
    setFormData({
      name: "",
      position: "",
      nmls: "1416824",
      nmls_personal: "",
      dre: "",
      directPhone: "",
      faxPhone: "",
      address: "",
      email: "",
      apply_now: "",
      secure_upload: "",
      reviews: "",
      facebook: "",
      instagram: "",
      linkedIn: "",
      schedule_appt: "",
    });
    setPreview(null);
  };

  const handleCopy = () => {
    if (tableRef.current) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(tableRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      console.log("Content copied to clipboard!");
      toast("Email Sign Copied", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="email-signature">
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <div className="spacer uploadAvatar">
          <Avatar
            width={120}
            height={120}
            onCrop={onCrop}
            onClose={onClose}
            label={"Choice"}
            onBeforeFileLoad={onBeforeFileLoad}
            onFileLoad={(file) => setFormData({ ...formData, avatar: file })}
          />
          {errors.avatar && <span className="error">{errors.avatar}</span>}
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="name"
            placeholder="Insert Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="position"
            placeholder="Insert job title/position"
            value={formData.position}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer three-col">
          <input
            type="text"
            className="input-field"
            name="nmls"
            placeholder="Insert NMLS if applicable"
            value={formData.nmls}
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="input-field"
            name="nmls_personal"
            placeholder="Insert personal NMLS"
            value={formData.nmls_personal}
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="input-field"
            name="dre"
            placeholder="Insert DRE #"
            value={formData.dre}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="directPhone"
            placeholder="Insert cell #"
            value={formData.directPhone}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="faxPhone"
            placeholder="Insert fax #"
            value={formData.faxPhone}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="address"
            placeholder="Insert office address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="email"
            placeholder="Insert email address"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="apply_now"
            placeholder="Insert apply now link funnel"
            value={formData.apply_now}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="secure_upload"
            placeholder="Insert secure upload link if applicable"
            value={formData.secure_upload}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer">
          <input
            type="text"
            className="input-field"
            name="reviews"
            placeholder="Insert reviews link if applicable"
            value={formData.reviews}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer two-col">
          <input
            type="text"
            name="facebook"
            className="input-field"
            placeholder="Facebook"
            value={formData.facebook}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="instagram"
            className="input-field"
            placeholder="Instagram"
            value={formData.instagram}
            onChange={handleInputChange}
          />
        </div>
        <div className="spacer two-col">
          <input
            type="text"
            name="linkedIn"
            className="input-field"
            placeholder="LinkedIn"
            value={formData.linkedIn}
            onChange={handleInputChange}
          />
          <input
            type="text"
            className="input-field"
            name="schedule_appt"
            placeholder="Insert schedule appt"
            value={formData.schedule_appt}
            onChange={handleInputChange}
          />
        </div>
        <button type="submit" className="button">
          COPY SIGNATURE
        </button>
      </form>
      <div className="preview">
        <div className="template">
          <table ref={tableRef} style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '530px', background: 'rgb(255, 255, 255)', textSizeAdjust: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '15px 0px 15px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                  <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '14px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                    Kind Regards,
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                  <table
                    height="173"
                    style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '530px', textSizeAdjust: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '12px 0px 0px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                          <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  style={{ padding: '0px', verticalAlign: 'top', width: '120px', textSizeAdjust: '100%' }}>
                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ fontSize: '1px', lineHeight: '7px', padding: '0px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {preview ? (
                                    <img
                                      src={preview}
                                      width="120"
                                      height="120"
                                      alt="Preview"
                                      style={{ display: 'block', border: '0px', lineHeight: '100%', outline: 'none', textDecoration: 'none' }}
                                    />
                                  ) : (
                                    <img
                                      src={profileAvatar}
                                      width="120"
                                      height="120"
                                      alt="Default"
                                      style={{ display: 'block', border: '0px', lineHeight: '100%', outline: 'none', textDecoration: 'none' }}
                                    />
                                  )}
                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: 'auto', textSizeAdjust: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: '0px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                                          <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                            <tbody>
                                              <tr>
                                                <th style={{padding: '12px 4px 0px'}}>
                                                  <a
                                                    href={
                                                      formData.facebook
                                                        ? formData.facebook
                                                        : "https://www.facebook.com/"
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ color: 'rgb(33, 153, 232)', textDecoration: 'none', textSizeAdjust: '100%' }}>
                                                    <img
                                                      src={fbIcon}
                                                      width="20"
                                                      height="20"
                                                      alt="facebook"
                                                      style={{ display: 'block', margin: '0px auto', border: '0px', lineHeight: '100%', outline: 'none', textDecoration: 'none' }} />
                                                  </a>
                                                </th>
                                                <th style={{padding: '12px 4px 0px'}}>
                                                  <a
                                                    href={
                                                      formData.instagram
                                                        ? formData.instagram
                                                        : "https://www.instagram.com/"
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ color: 'rgb(33, 153, 232)', textDecoration: 'none', textSizeAdjust: '100%' }}
                                                  >
                                                    <img
                                                      src={InstaIcon}
                                                      width="20"
                                                      height="20"
                                                      alt="instagram"
                                                      style={{ display: 'block', margin: '0px auto', border: '0px', lineHeight: '100%', outline: 'none', textDecoration: 'none' }} />
                                                  </a>
                                                </th>
                                                <th style={{padding: '12px 4px 0px'}}>
                                                  <a
                                                    href={
                                                      formData.linkedIn
                                                        ? formData.linkedIn
                                                        : "https://www.linkedin.com/"
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{ color: 'rgb(33, 153, 232)', textDecoration: 'none', textSizeAdjust: '100%' }}>
                                                    <img
                                                      src={linkedinIcon}
                                                      width="20"
                                                      height="20"
                                                      alt="linkedin"
                                                      style={{ display: 'block', margin: '0px auto', border: '0px', lineHeight: '100%', outline: 'none', textDecoration: 'none' }} />
                                                  </a>
                                                </th>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td style={{ padding: '0px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                                  <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '16px', lineHeight: '20px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                    <strong style={{ textTransform: 'uppercase' }}>
                                    {formData.name ? formData.name : "Nancy Bayat"}
                                    </strong>
                                    {" "}|{" "}AMERIFY powered by EMC
                                  </p>
                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{ fontSize: '1px', lineHeight: '4px', padding: '0px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                    <tbody>
                                      <tr>
                                        <td style={{ padding: '0px 0px 0px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '15px', lineHeight: '17px', fontWeight: '500', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%', textTransform: 'uppercase' }}>
                                          {formData.position ? formData.position : "Vice President"}
                                          </p>
                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '12px', fontWeight: '500', lineHeight: '14px', margin: '10px auto 0', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                            {formData.nmls ? `CORP NMLS ${formData.nmls}` : ""}
                                            {formData.nmls_personal ? ` ${formData.nmls ? "|" : ""} NMLS ${formData.nmls_personal}` : ""}
                                            {formData.dre ? ` ${formData.nmls || formData.nmls_personal ? "|" : ""} DRE ${formData.dre}` : ""}
                                          </p>
                                          <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: 'auto', textSizeAdjust: '100%' }}>
                                            <tbody>
                                              <tr>
                                                <td style={{padding: '0px'}}>
                                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: 'auto', textSizeAdjust: '100%' }}>
                                                    <tbody>
                                                      <tr>
                                                        <td style={{padding: '0px'}}>
                                                          <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: 'auto', textSizeAdjust: '100%' }}>
                                                            <tbody>
                                                              <tr>
                                                                <td style={{ padding: '0px 0px 3px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                                                                  <p style={{ color: 'rgb(101,101,103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '12px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                                                    <a
                                                                      href={
                                                                        formData.directPhone
                                                                          ? `tel:${formData.directPhone}`
                                                                          : "tel:9493355555"
                                                                      }
                                                                      style={{ color: 'rgb(101,101,103)', textDecoration: 'none', textSizeAdjust: '100%', fontWeight: '500', borderBottom: '2px solid rgb(22,144,245)', fontSize: '12px' }}>
                                                                      Direct:{" "}{formData.directPhone ? formData.directPhone : "949-335-5555"}
                                                                    </a>
                                                                  </p>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                        <td style={{ padding: '0px', width: '10px' }}></td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: '0px'}}>
                                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                                    <tbody>
                                                      <tr>
                                                        <td style={{ padding: '0px 0px 3px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '12px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                                            <a
                                                              href={
                                                                formData.faxPhone
                                                                  ? `fax:${formData.faxPhone}`
                                                                  : "fax:9493355556"
                                                              }
                                                              style={{ color: 'rgb(101, 101, 103)', textDecoration: 'none', fontWeight: '500', borderBottom: '2px solid rgb(22, 144, 245)', textSizeAdjust: '100%', fontSize: '12px' }}>
                                                              Fax:{" "}{formData.faxPhone ? formData.faxPhone : "949-335-5555"}
                                                            </a>
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: '0px'}}>
                                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                                    <tbody>
                                                      <tr>
                                                        <td style={{ padding: '0px 0px 3px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '12px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                                            <span style={{ textDecoration: 'none', fontWeight: '500', textTransform: 'uppercase', borderBottom: '2px solid rgb(22, 144, 245)', textSizeAdjust: '100%', fontSize: '12px' }}>
                                                              {formData.email ? formData.email : "nancy@amerify.com"}
                                                            </span>
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ padding: '0px'}}>
                                                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                                                    <tbody>
                                                      <tr>
                                                        <td style={{ padding: '0px 0px 3px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '12px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                                            <a
                                                              style={{ textDecoration: 'none', textSizeAdjust: '100%', textTransform: 'uppercase', fontWeight: '500', fontSize: '12px' }}>
                                                              {formData.address ? formData.address : "18071 Fitch #200, Irvine, CA 92614"}
                                                            </a>
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <p style={{ color: 'rgb(101, 101, 103)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '13px', lineHeight: '16px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                                            <a
                                              href={formData.apply_now ? formData.apply_now : ""}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ color: 'rgb(45, 43, 255)', borderBottom: '2px solid rgb(22, 144, 245)', fontWeight: '500', textDecoration: 'none', textSizeAdjust: '100%', fontSize: '13px' }}>
                                              Apply Now
                                            </a>
                                            <span
                                              style={{ color: 'rgb(82, 175, 230)', fontWeight: 'bold' }}>
                                              {" "}|{" "}
                                            </span>
                                            <a
                                              href={formData.secure_upload ? formData.secure_upload : ""}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ color: 'rgb(45, 43, 255)', borderBottom: '2px solid rgb(22, 144, 245)', fontWeight: '500', textDecoration: 'none', textSizeAdjust: '100%', fontSize: '13px' }}>
                                              Secure Upload
                                            </a>
                                            <span
                                              style={{ color: 'rgb(82, 175, 230)', fontWeight: 'bold' }}>
                                              {" "}|{" "}
                                            </span>
                                            <a
                                              href={formData.reviews ? formData.reviews : ""}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ color: 'rgb(45, 43, 255)', borderBottom: '2px solid rgb(22, 144, 245)', fontWeight: '500', textDecoration: 'none', textSizeAdjust: '100%', fontSize: '13px' }}>
                                              Reviews
                                            </a>
                                            <span
                                              style={{ color: 'rgb(82, 175, 230)', fontWeight: 'bold' }}>
                                              {" "}|{" "}
                                            </span>
                                            <a
                                              href={formData.schedule_appt ? formData.schedule_appt : ""}
                                              target="_blank"
                                              rel="noreferrer"
                                              style={{ color: 'rgb(45, 43, 255)', borderBottom: '2px solid rgb(22, 144, 245)', fontWeight: '500', textDecoration: 'none', textSizeAdjust: '100%', fontSize: '13px' }}>
                                              Schedule Time with Me
                                            </a>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '15px 0px 15px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                  <table style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '100%', textSizeAdjust: '100%' }}>
                    <tbody>
                      <tr>
                        <td style={{ padding: '0px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                          <a
                            href="https://www.amerify.com/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', textSizeAdjust: '100%' }}>
                            <img
                              src={amerifyLogo}
                              width="195"
                              alt=""
                              style={{ display: 'block', border: '0px', height: 'auto', lineHeight: '100%', outline: 'none', textDecoration: 'none' }} />
                          </a>
                        </td>
                        <td style={{ padding: '0px', verticalAlign: 'middle', textSizeAdjust: '100%' }}>
                          <img
                            src={awardsImages}
                            width="320"
                            alt=""
                            style={{ display: 'block', float: 'right', textAlign: 'right', border: '0px', height: 'auto', lineHeight: '100%', outline: 'none', textDecoration: 'none' }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 0px 20px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                  <p style={{ color: 'rgb(118, 113, 113)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '10px', lineHeight: '20px', margin: '0px auto', padding: '0px', textAlign: 'left', textSizeAdjust: '100%' }}>
                    CONFIDENTIALITY NOTICE: This e-mail transmission, and any
                    documents, files or previous e-mail messages attached to it
                    may contain confidential information that is legally
                    privileged. If you are not the intended recipient, or if you
                    are not responsible for delivering it to the intended
                    recipient, you are hereby notified that any disclosure,
                    copying, distribution or use of any of the information
                    contained in or attached to this transmission is STRICTLY
                    PROHIBITED. For legal advice, please consult with an
                    attorney. For tax advice, please consult with an accountant.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0px 0px 5px 15px', verticalAlign: 'top', textSizeAdjust: '100%' }}>
                  <p style={{ color: 'rgb(255, 0, 0)', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif", fontSize: '10px', lineHeight: '20px', margin: '0px auto', padding: '0px', textAlign: 'left', fontWeight: 'bold', textSizeAdjust: '100%' }}>
                    IMPORTANT NOTICE: DURING YOUR MORTGAGE LOAN TRANSACTION - 1)
                    Please continue to make your mortgage payment at all times.
                    2) Please do not quit your job in the middle of your
                    refinance / purchase transaction. 3) Please do not
                    accumulate new debt or co-sign any new debt
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="spacer">
          <textarea
            rows="15"
            id="emailTemplateTextarea"
            className="input-field"
            style={{margin: '15px 0px 0px 15px', width: 'calc(530px - 15px)'}}
          ></textarea>
        </div>
        <p className="spacer two-col" style={{margin: '15px 0px 0px 15px'}}>
          <button className="button" onClick={handleGenerate}>
            Generate
          </button>
          <button className="button" onClick={handleClear}>
            Clear
          </button>
        </p>
      </div>
    </div>
  );
}

export default App;
